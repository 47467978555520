define("app-web/routes/aplicacion", ["exports", "@ember/routing/route", "rsvp", "fetch", "app-web/config/environment", "@ember/service", "ember-fetch/errors", "ember-changeset", "ember-changeset-validations", "app-web/validations/candidato", "app-web/validators/dependent-presence"], function (_exports, _route, _rsvp, _fetch, _environment, _service, _errors, _emberChangeset, _emberChangesetValidations, _candidato, _dependentPresence) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let AplicacionRoute = (_class = class AplicacionRoute extends _route.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "validations", _candidato.default);

      _initializerDefineProperty(this, "toast", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);

      _initializerDefineProperty(this, "loader", _descriptor3, this);

      _initializerDefineProperty(this, "themeChanger", _descriptor4, this);
    }

    model(params) {
      let {
        host
      } = _environment.default.API;
      this.loader.setIsLoading();
      return (0, _rsvp.hash)({
        aplicacion: (0, _fetch.default)(`${host}/requerimiento/${params.hash}`).then(function (response) {
          if (response.ok) {
            return response.json();
          } else if ((0, _errors.isBadRequestResponse)(response)) {
            return response.json();
          } else if ((0, _errors.isNotFoundResponse)(response)) {
            return response.json();
          }
        }).catch(error => {
          this.toast.error(error);
          throw error;
        }),
        candidato: this.store.createRecord('candidato', {
          hashRequerimiento: params.hash,
          emails: [],
          celulares: [],
          telefonos: [],
          numerosEmergencia: [],
          disponible: 1
        }),
        paises: this.store.query('pais', {
          filter: {
            page_enabled: null
          }
        }),
        paisesReclutamiento: this.store.query('pais', {
          filter: {
            disponibleReclutamiento: 1
          }
        }),
        fuentesReclutamiento: this.store.query('fuente-reclutamiento', {
          filter: {
            activo: 1
          }
        })
      });
    }

    setupController(controller, model) {
      super.setupController(controller, model);
      this.loader.setNotLoading();
      let changeset = new _emberChangeset.default(model.candidato, (0, _emberChangesetValidations.default)(this.validations), this.validations); // Configure validators that require the changeset to be already defined.

      this.validations['uid'] = (0, _dependentPresence.default)({
        changeset: changeset,
        dependsOn: 'pasaporte'
      });
      this.validations['pasaporte'] = (0, _dependentPresence.default)({
        changeset: changeset,
        dependsOn: 'uid'
      });
      controller.set('changeset', changeset);

      if (model.aplicacion.requerimiento) {
        let codigoClienteTribal = '10548702-3';

        if (model.aplicacion.requerimiento.cliente === codigoClienteTribal) {
          this.themeChanger.set('theme', 'dark');
        }
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "toast", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "loader", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "themeChanger", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  _exports.default = AplicacionRoute;
});