define("app-web/models/candidato", ["exports", "@ember-data/model", "rsvp"], function (_exports, _model, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _dec25, _dec26, _dec27, _dec28, _dec29, _dec30, _dec31, _dec32, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _descriptor22, _descriptor23, _descriptor24, _descriptor25, _descriptor26, _descriptor27, _descriptor28, _descriptor29, _descriptor30, _descriptor31, _descriptor32;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const {
    all
  } = _rsvp.default;
  let CandidatoModel = (_dec = (0, _model.attr)('string'), _dec2 = (0, _model.attr)('string'), _dec3 = (0, _model.attr)('string'), _dec4 = (0, _model.attr)('string'), _dec5 = (0, _model.attr)('string'), _dec6 = (0, _model.attr)('string'), _dec7 = (0, _model.attr)('custom-date'), _dec8 = (0, _model.attr)('string'), _dec9 = (0, _model.attr)('string'), _dec10 = (0, _model.attr)('string'), _dec11 = (0, _model.belongsTo)('pais'), _dec12 = (0, _model.belongsTo)('pais'), _dec13 = (0, _model.attr)('string'), _dec14 = (0, _model.attr)('string'), _dec15 = (0, _model.attr)(), _dec16 = (0, _model.attr)(), _dec17 = (0, _model.attr)(), _dec18 = (0, _model.attr)(), _dec19 = (0, _model.attr)('string'), _dec20 = (0, _model.attr)('string'), _dec21 = (0, _model.attr)('boolean', {
    defaultValue: false
  }), _dec22 = (0, _model.attr)('string'), _dec23 = (0, _model.attr)('string'), _dec24 = (0, _model.attr)('boolean', {
    defaultValue: false
  }), _dec25 = (0, _model.attr)('string'), _dec26 = (0, _model.attr)('string'), _dec27 = (0, _model.attr)('string'), _dec28 = (0, _model.attr)('string'), _dec29 = (0, _model.attr)('number'), _dec30 = (0, _model.hasMany)('experiencia-laboral'), _dec31 = (0, _model.hasMany)('candidato-archivo'), _dec32 = (0, _model.belongsTo)('fuente-reclutamiento'), (_class = class CandidatoModel extends _model.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "codigo", _descriptor, this);

      _initializerDefineProperty(this, "primerNombre", _descriptor2, this);

      _initializerDefineProperty(this, "segundoNombre", _descriptor3, this);

      _initializerDefineProperty(this, "primerApellido", _descriptor4, this);

      _initializerDefineProperty(this, "segundoApellido", _descriptor5, this);

      _initializerDefineProperty(this, "apellidoCasada", _descriptor6, this);

      _initializerDefineProperty(this, "fechaNacimiento", _descriptor7, this);

      _initializerDefineProperty(this, "uid", _descriptor8, this);

      _initializerDefineProperty(this, "nit", _descriptor9, this);

      _initializerDefineProperty(this, "pasaporte", _descriptor10, this);

      _initializerDefineProperty(this, "paisNacimiento", _descriptor11, this);

      _initializerDefineProperty(this, "paisVecindad", _descriptor12, this);

      _initializerDefineProperty(this, "direccion", _descriptor13, this);

      _initializerDefineProperty(this, "sexo", _descriptor14, this);

      _initializerDefineProperty(this, "celulares", _descriptor15, this);

      _initializerDefineProperty(this, "telefonos", _descriptor16, this);

      _initializerDefineProperty(this, "emails", _descriptor17, this);

      _initializerDefineProperty(this, "numerosEmergencia", _descriptor18, this);

      _initializerDefineProperty(this, "gradoAcademico", _descriptor19, this);

      _initializerDefineProperty(this, "gradoAcademicoDescripcion", _descriptor20, this);

      _initializerDefineProperty(this, "tieneCuentaBi", _descriptor21, this);

      _initializerDefineProperty(this, "tipoCuentaBi", _descriptor22, this);

      _initializerDefineProperty(this, "numeroCuentaBi", _descriptor23, this);

      _initializerDefineProperty(this, "tieneCuentaExtranjero", _descriptor24, this);

      _initializerDefineProperty(this, "nombreBancoExtranjero", _descriptor25, this);

      _initializerDefineProperty(this, "tipoCuentaExtranjero", _descriptor26, this);

      _initializerDefineProperty(this, "numeroCuentaExtranjero", _descriptor27, this);

      _initializerDefineProperty(this, "hashRequerimiento", _descriptor28, this);

      _initializerDefineProperty(this, "expectativaSalarial", _descriptor29, this);

      _initializerDefineProperty(this, "experiencias", _descriptor30, this);

      _initializerDefineProperty(this, "archivos", _descriptor31, this);

      _initializerDefineProperty(this, "fuenteReclutamiento", _descriptor32, this);
    }

    save(options) {
      if (this.isDeleted) {
        return super.save(options);
      }

      let experiencias = this.experiencias.filter(experiencia => (experiencia.get('isNew') || experiencia.get('isDirty')) && !experiencia.get('isDeleted'));
      let archivos = this.archivos.filter(archivo => (archivo.get('isNew') || archivo.get('isDirty')) && !archivo.get('isDeleted'));
      return this._internalModel.save(options).then(() => all(experiencias.map(e => e.save()))).then(() => all(archivos.map(a => a.save()))).then(() => this);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "codigo", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "primerNombre", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "segundoNombre", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "primerApellido", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "segundoApellido", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "apellidoCasada", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "fechaNacimiento", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "uid", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "nit", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "pasaporte", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "paisNacimiento", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "paisVecindad", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "direccion", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "sexo", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "celulares", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "telefonos", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "emails", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "numerosEmergencia", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "gradoAcademico", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor20 = _applyDecoratedDescriptor(_class.prototype, "gradoAcademicoDescripcion", [_dec20], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor21 = _applyDecoratedDescriptor(_class.prototype, "tieneCuentaBi", [_dec21], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor22 = _applyDecoratedDescriptor(_class.prototype, "tipoCuentaBi", [_dec22], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor23 = _applyDecoratedDescriptor(_class.prototype, "numeroCuentaBi", [_dec23], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor24 = _applyDecoratedDescriptor(_class.prototype, "tieneCuentaExtranjero", [_dec24], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor25 = _applyDecoratedDescriptor(_class.prototype, "nombreBancoExtranjero", [_dec25], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor26 = _applyDecoratedDescriptor(_class.prototype, "tipoCuentaExtranjero", [_dec26], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor27 = _applyDecoratedDescriptor(_class.prototype, "numeroCuentaExtranjero", [_dec27], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor28 = _applyDecoratedDescriptor(_class.prototype, "hashRequerimiento", [_dec28], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor29 = _applyDecoratedDescriptor(_class.prototype, "expectativaSalarial", [_dec29], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor30 = _applyDecoratedDescriptor(_class.prototype, "experiencias", [_dec30], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor31 = _applyDecoratedDescriptor(_class.prototype, "archivos", [_dec31], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor32 = _applyDecoratedDescriptor(_class.prototype, "fuenteReclutamiento", [_dec32], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = CandidatoModel;
});