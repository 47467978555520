define("app-web/validations/candidato", ["exports", "ember-changeset-validations/validators", "app-web/validators/list-of-emails"], function (_exports, _validators, _listOfEmails) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    primerNombre: (0, _validators.validatePresence)(true),
    primerApellido: (0, _validators.validatePresence)(true),
    fechaNacimiento: (0, _validators.validatePresence)(true),
    expectativaSalarial: (0, _validators.validatePresence)(true),
    paisVecindad: (0, _validators.validatePresence)({
      presence: true,
      message: `País residencia no puede estar vacío`
    }),
    celulares: [(0, _validators.validateLength)({
      min: 1
    }), (0, _validators.validatePresence)({
      presence: true
    })],
    telefonos: [(0, _validators.validateLength)({
      min: 1
    }), (0, _validators.validatePresence)({
      presence: true
    })],
    emails: [(0, _validators.validateLength)({
      max: 1
    }), (0, _listOfEmails.default)(), (0, _validators.validatePresence)({
      presence: true
    })],
    paisNacimiento: (0, _validators.validatePresence)({
      presence: true,
      message: `País nacimiento no puede estar vacío`
    }),
    fuenteReclutamiento: (0, _validators.validatePresence)(true),
    sexo: (0, _validators.validatePresence)(true)
  };
  _exports.default = _default;
});